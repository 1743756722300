import * as React from 'react';
import Helmet from 'react-helmet';

import { Tour } from 'components/tour/Tour';

export default () => (
  <>
    <Helmet title="Tour" />

    <Tour
      title="Tour of Malbygg"
      text={
        <React.Fragment>
          <p>Visit our brewery and learn everything about Malbygg, our brewing process and get a taste our beers.</p>
          <p><a href="mailto:malbygg@malbygg.is">Email</a> us for availability.</p>
        </React.Fragment>
      }
    />
  </>
);
